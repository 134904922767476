import * as React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Box,
  Heading,
  Image,
  VStack
} from "@chakra-ui/react";


interface Props {
  node: any
}


export const TeamMember: React.FC<Props> = ( props ) => {
  return (
    <Box
      display="inline-block"
    >
      <VStack
        justify="center"
        align="center"
        spacing="24px"
        m={{
          base: '0 32px',
          xl: '0'
        }}
      >
        <Image
          as={ GatsbyImage }
          image={ props.node.frontmatter.image.childImageSharp.gatsbyImageData }
          w="224px"
          h="297px"
          background="background.aliceblue"
        />
        <Heading
          as="h3"
          color="text.primary"
          fontSize="20px"
          fontWeight={ 700 }
        >
          { props.node.frontmatter.name }
        </Heading>
        <Heading
          as="h4"
          color="text.secondary"
          fontSize="16px"
          fontWeight={ 400 }
        >
          { props.node.frontmatter.position }
        </Heading>
      </VStack>
    </Box>
  )
}
