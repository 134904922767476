import * as React from "react";
import { Link as GatsbyLink } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  Container,
  Flex,
  Heading,
  Image,
  Link
} from "@chakra-ui/react";

import {
  ArrowRightIcon
} from "../../ScalableVectorGraphics";


interface Props {
  node: any
}


export const Service: React.FC<Props> = ( props ) => {
  return (
    <Link
      as={ GatsbyLink }
      to={ props.node.fields.slug }
      display="inline-block"
      position="relative"
      w="290px"
      h="200px"
      bg="white"
      m="0"
      zIndex={ 1 }
      transition="all 0.2s ease-in-out"
      _hover={{
        opacity: '0.6',
        textDecoration: 'none'
      }}
    >
      <Container
        maxW="100%"
        w="100%"
        h="140px"
        padding="24px"
        transition="all 0.2s ease-in-out"
      >
        <Heading
          as="h2"
          fontSize="20px"
        >
          { props.node.frontmatter.heading }
        </Heading>
        <Heading
          as="h6"
          pt="8px"
          fontSize="16px"
          fontWeight={ 400 }
          color="text.secondary"
        >
          0{ props.node.frontmatter.order }
        </Heading>
      </Container>
      <Flex
        justifyContent="center"
        alignItems="center"
        position="absolute"
        right="28px"
        bottom="48px"
        w="24px"
        h="24px"
        bg="primary.900"
        borderRadius="50%"
        zIndex={ 1 }
      >
        <ArrowRightIcon
          w="6px"
          h="12px"
          color="white"
        />
      </Flex>
      <Image
        as={ GatsbyImage }
        image={ props.node.frontmatter.card_image.childImageSharp.gatsbyImageData }
        w="100%"
        h="auto"
      />
    </Link>
  )
}
