import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  VStack
} from "@chakra-ui/react";

import {
  Service,
  TeamMember
} from "./Layouts";


interface Props {
  nodes: any[]
  layout: string

  heading?: React.ReactNode | string | undefined
  buttonText?: string | undefined
  slug?: string | undefined
}


const SCROLL_BOX_MIN_WIDTH = 20;


export const Scroller: React.FC<Props> = ( props ) => {
  const [scrollBoxWidth, setScrollBoxWidth] = useState( SCROLL_BOX_MIN_WIDTH );
  const [scrollBoxLeft, setScrollBoxLeft] = useState( 0 );
  const [scrollbarThumbPos, setScrollbarThumbPos] = useState( 0 );
  const [isDragging, setDragging] = useState( false );

  const handleDocumentMouseUp = useCallback( ( e ) => {
    if ( isDragging ) {
      e.preventDefault();
      setDragging( false );
    }
  }, [isDragging] );

  const handleDocumentMouseMove = useCallback( ( e ) => {
    if (isDragging) {
      e.preventDefault();
      e.stopPropogation();

      const scrollHostElement = scrollHostRef.current;
      const { scrollWidth, offsetWidth } = scrollHostElement;

      let deltaX = e.clientX - scrollbarThumbPos;
      let percentage = deltaX * ( scrollWidth / offsetWidth );

      setScrollbarThumbPos(e.clientX);
      setScrollBoxLeft(
        Math.min(
          Math.max( 0, scrollBoxLeft + deltaX ),
          offsetWidth - scrollBoxWidth
        )
      );

      scrollHostElement.scrollLeft = Math.min(
        scrollHostElement.scrollLeft + percentage,
        scrollWidth - offsetWidth
      );
    }
  }, [isDragging, scrollbarThumbPos, scrollBoxWidth, scrollBoxLeft] );

  const handleScrollThumbMouseDown = useCallback( ( e ) => {
    e.preventDefault();
    e.stopPropogation();

    setScrollbarThumbPos( e.clientX );
    setDragging(true);
  }, [] );

  const handleScroll = useCallback( () => {
    if ( !scrollHostRef ) {
      return;
    }

    const scrollHostElement = scrollHostRef.current;
    const { scrollLeft, scrollWidth, offsetWidth } = scrollHostElement;

    let newLeft = ( parseInt( scrollLeft, 10 ) / parseInt( scrollWidth, 10 ) ) * offsetWidth;

    newLeft = Math.min( newLeft, offsetWidth - scrollBoxLeft );

    setScrollBoxLeft( newLeft );
  }, []);

  const scrollHostRef = useRef();

  useEffect( () => {
    const scrollHostElement = scrollHostRef.current;
    const { clientWidth, scrollWidth } = scrollHostElement;
    const scrollThumbPercentage = clientWidth / scrollWidth;
    const scrollThumbWidth = Math.max(
      scrollThumbPercentage * clientWidth,
      SCROLL_BOX_MIN_WIDTH
    );

    setScrollBoxWidth( scrollThumbWidth );

    scrollHostElement.addEventListener( "scroll", handleScroll, true );

    return function cleanup() {
      scrollHostElement.removeEventListener( "scroll", handleScroll, true );
    }
  }, []);

  useEffect( () => {
    document.addEventListener( "mousemove", handleDocumentMouseMove );
    document.addEventListener( "mouseup", handleDocumentMouseUp );
    document.addEventListener( "mouseleave", handleDocumentMouseUp );

    return function cleanup() {
      document.removeEventListener( "mousemove", handleDocumentMouseMove );
      document.removeEventListener( "mouseup", handleDocumentMouseUp );
      document.removeEventListener( "mouseleave", handleDocumentMouseUp );
    };
  }, [handleDocumentMouseMove, handleDocumentMouseUp] );

  const nodeList = props.nodes.map( ( node: any, _index: number ) => {
    switch ( props.layout ) {
      case 'service':
        return (
          <Service
            key={ _index }
            node={ node.node }
          />
        );
      case 'team':
        return (
          <TeamMember
            key={ _index }
            node={ node.node }
          />
        )
      default:
        return null;
    }
  })

  return (
    <VStack
      justify="flex-start"
      align="flex-start"
      spacing="52px"
      w="100%"
      m="0 auto"
    >
      { props.heading &&
        <Heading
          as="h2"
          fontSize="48px"
          fontWeight={ 700 }
        >
          { props.heading }
        </Heading>
      }
      <HStack
        ref={ scrollHostRef }
        display={{
          base: 'block'
        }}
        align="flex-start"
        spacing={{
          base: '32px'
        }}
        w="100%"
        overflow="auto"
        whiteSpace="nowrap"
        css={{
          WebkitOverflowScrolling: 'auto',
          msOverflowStyle: 'none',
          scrollWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        { nodeList }
      </HStack>
      <Box
        position="relative"
        w="100%"
      >
        <Box
          position="absolute"
          left={ 0 }
          bottom={ 0 }
          right={ 0 }
          w="100%"
          h="2px"
          bg="border.gainsboro"
        >
          <Box
            position="absolute"
            left={ scrollBoxLeft }
            w={ scrollBoxWidth }
            h="2px"
            opacity={ 1 }
            bg="primary.900"
            onMouseDown={ handleScrollThumbMouseDown }
          />
        </Box>
      </Box>
      { props.slug &&
        <Flex
          justifyContent="center"
          w="100%"
        >
          <Button
            as={ GatsbyLink }
            to={ props.slug }
            variant="solid"
            h="42px"
            background="primary.900"
            borderRadius="21px"
            borderColor="primary.900"
            color="white"
            fontSize="14px"
            fontWeight={ 500 }
          >
            { props.buttonText }
          </Button>
        </Flex>
      }
    </VStack>
  )
}
